import { Link } from 'react-router-dom';
import styled from "styled-components";

export const Bar = styled.div`
    padding: 0.5% 2.5%;
    background: #FFFFFF;
    position: fixed;
    top: 0px;
    width: 95%;
    height: 70px;
    max-height: 70px;
    vertical-align: center;
    display: flex;
    align-items: center;
    box-shadow: 0px 2px 0px 0px #DCDCDC;
    z-index: 1;
    
    @media (max-width: 600px) {
        padding: 1% 2.5%;
    }
`;

export const BarContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 1000px;
    height: 100%;
    margin: 0 auto;
`;

export const BarBrandLogo = styled(Link)`
    cursor: pointer;
`;

export const BarGrid = styled.ul`
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end; /* end = right side of page, start = left */
    margin-right: 2rem;
`;

export const BarItem = styled.li`
    display: flex;
    align-items: center;
    height: 80px;

    @media screen and (max-width: 500px) {
        .nav-item {
            flex-direction: column;
            height: auto;
        }
    }
`;
