import React from "react";
// Information We Collect
const PrivacySection3 = () => {
    return (
        <div>
            <h3>3. Information We Collect</h3>
            You choose to give us certain information when using our services. This includes:
            <ul>
                <li>
                When you create an account, you provide us with at least your email address and name.
                </li>
                <br/>
                <li>
                You can improve your user experience by voluntarily providing us with additional information when using the Service. 
                We will handle your additional information with your consent and/or to provide the services you have requested. 
                This includes information from your profile as well as material such as images, videos, audio clips, or other interactions with the platform.  
                You may enable us access to your camera, photo book, or microphone in order to post certain content, such as images or movies.
                </li>
                <br/>
                <li>
                When you are involved in surveys, focus groups or market research, you give us insightful information about our offerings.  
                This includes your responses to our questions and testimonials.
                </li>
                <br/>
                <li>
                When you opt to engage in our promotions or events, we collect the information you provide during the registration or entry process.
                </li>
                <br/>
                <li>
                If you contact us (e.g., through our support team or on social media), we collect the information you give us during the interaction.
                </li>
                <br/>
                <li>
                We process the content you publish to keep our services operational and secure, and to keep our community safe.
                </li>
            </ul>
            <br/>
            <br/>
            <b><i>Information we receive from others</i></b>
            <br/>
            <br/>
            In addition to the information you directly provide us, we may be provided with information about you from other sources, such as:
            <br/>
            <ul>
                <li>
                Users may provide information about you as while using our services, such as through interactions with you or by submitting a reports that involve you.
                </li>
                <br/>
                <li>
                You may decide to share information with us through your social media account, 
                for instance if you decide to create and log into your Flashycards account via your social media or other account (e.g., Google or Apple).
                </li>
                <br/>
                <li>
                We may receive information about you from our partners when our advertisements are featured on their services, 
                and in such cases, they may provide details on the success of a campaign.  
                Within legal constraints, we may obtain information about individuals with suspected or confirmed improper conduct from
                 third parties, as part of our efforts to ensure the safety and security of our users.
                </li>
            </ul>
            <br/>
            <br/>
            <b><i>Information generated or automatically collected when you use our services</i></b>
            <br/>
            <br/>
            When you use our services, this generates technical data about which features you’ve used, how you’ve used them and the devices you use to access our services. See below for more details.
            <ul>
                <li>
                <b>Usage Information.</b>  Using the services generates data about your activity on our services, for instance how you use them 
                (e.g., when you logged in, features you’ve been using, actions taken, information shown to you, referring webpages address 
                and ads that you interacted with) and your interactions with other users (e.g., users you interact with).
                </li>
                <br/>
                <li>
                <b>Device Information.</b>  We collect information from and about the device(s) you use to access our services, including 
                hardware and software information such as IP address, device time zone, device ID and type, 
                apps settings and characteristics, app crashes, advertising IDs (which are randomly generated numbers that you can 
                reset by going into your device’ settings and, in some cases, disable entirely), and identifiers associated with cookies or other technologies 
                that may uniquely identify a device or browser.
                </li>
                <br/>
                <li>
                <b>Information collected by cookies and similar technologies.</b>  We utilise, and may permit others to use, cookies and similar technologies such as SDKs and authentication tokens to identify you and/or your device(s).
                <br/>
                <br/>
                Certain web browsers (e.g., Safari, Internet Explorer, Firefox, and Chrome) offer a 'Do Not Track' (DNT) function, which 
                indicates that a user does not want their online actions to be tracked. When a website recognises and responds to a 
                DNT signal, the browser can limit the gathering of specified information about the user.  However, not all browsers support DNT, 
                and DNT signal interpretation is not yet uniform.  As a result, many businesses, including Flashycards, do not now respond to DNT signals.
                </li>
            </ul>
        </div>
    )
}

export default PrivacySection3;