import React from "react";
// Limitation of Liability
const TermsSection12 = () => {
    return (
        <div>
            <h3>12. Limitation of Liability</h3>
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, FLASHYCARDS, ALONG WITH ITS AFFILIATES, EMPLOYEES, LICENSORS, 
            OR SERVICE PROVIDERS, SHALL NOT BE LIABLE FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, PUNITIVE, 
            OR ENHANCED DAMAGES, INCLUDING, WITHOUT LIMITATION, LOSS OF PROFITS, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY 
            LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES. SUCH DAMAGES MAY ARISE FROM: (I) YOUR ACCESS TO OR USE OF, 
            OR INABILITY TO ACCESS OR USE, THE SERVICE; (II) THE CONDUCT OR CONTENT OF OTHER MEMBERS OR THIRD PARTIES ON, THROUGH, 
            OR FOLLOWING USE OF THE SERVICE; OR (III) UNAUTHORISED ACCESS, USE, OR ALTERATION OF YOUR CONTENT, EVEN IF FLASHYCARDS 
            HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.  IN NO EVENT SHALL FLASHYCARDS' AGGREGATE LIABILITY TO YOU FOR ALL 
            CLAIMS RELATING TO THE SERVICE EXCEED THE GREATER OF ONE HUNDRED U.S. DOLLARS (U.S. $100.00) OR THE AMOUNT YOU PAID US, 
            IF ANY, BY YOU TO FLASHYCARDS FOR THE SERVICE OR IN THE PAST SIX MONTHS FOR THE SERVICES GIVING RISE TO THE CLAIM.
            <br/>
            <br/>
            SOME JURISDICTIONS MAY NOT PERMIT THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES; THUS, SOME OR ALL OF THE 
            EXCLUSIONS AND LIMITATIONS SET FORTH IN THIS SECTION MAY NOT APPLY TO YOU.
        </div>
    )
}

export default TermsSection12;