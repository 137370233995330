import React from "react";
// How long we retain your information
const PrivacySection7 = () => {
    return (
        <div>
            <h3>7. How long we retain your information</h3>
            We keep your personal information for as long as required for legitimate business reasons and to comply with applicable legal obligations. 
            If you decide to stop using our services, you can delete your account, which will result in your account being hidden to other users. 
            Following the deactivation of your account, we will securely and quickly delete your personal information in the following manner:
            <ul>
                <li>
                We retain a minimal amount of data to fulfil our legal obligations regarding data retention. 
                In particular, transaction data is kept for a period of ten years to comply with tax and accounting rules. 
                In addition, "traffic data" or logs may be kept for one year to meet legal data retention requirements.  
                User consent records are kept for five years as proof of our compliance with applicable legislation.
                </li>
                <br/>
                <li>
                We retain a restricted amount of information based on our legitimate interests.  
                This includes customer care records and associated data, as well as details of downloads/purchases, 
                in order to inform our customer care actions, enforce our rights, and aid our defence in the case of a dispute.  
                We also retain profile data in case of future litigation, to help with the establishment, exercise, or defence of legal rights. 
                Furthermore, we keep data needed to prevent banned individuals from registering new accounts for a period of time that ensures our users' safety and interests.
                </li>
                <br/>
                <li>
                In circumstances where there is an existing or potential issue, or dispute that justifies the preservation of information, 
                we retain information based on our legitimate interests.  This includes situations in which we are obligated to comply with 
                our legal duties in response to a legitimate subpoena or request to preserve data.  We may also keep data if it is considered essential for legal proceedings.
                </li>
            </ul>
        </div>
    )
}

export default PrivacySection7;