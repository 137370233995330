import React from "react";
// Acceptance of Terms of Use Agreement
const TermsSection1 = () => {
    return (
        <div>
            <h3>1. Acceptance of Terms of Use Agreement</h3>
            You acknowledge and accept the conditions stated in this Agreement by accessing or using our Services via our website, 
            the Flashycards mobile application, or any other platforms or services offered by Flashycards (collectively referred to as the "Service" or "Services"). 
            This Agreement applies to all who use our Services, regardless of registration or subscription status.  
            Within this Agreement, the terms "you" and "your" pertain to you personally.
            <br/>
            <br/>
            We retain the right to modify this Agreement and the Service on a regular basis for reasons such as legislative changes, 
            the addition of new features, or the adjustment of business practises.  You declare your acceptance of the revised Agreement by 
            continuing to use the Service after the changes take effect.  The most recent version of this Agreement can be accessed at (www.flashycards.app/terms),
             and it dictates your use of the Service. Should we make material changes to your rights or obligations, we will inform you at least 30 days 
             before the changes take effect (unless prohibited by applicable law). This message may be sent in a variety various ways, including inside the Service or via email.
        </div>
    )
}

export default TermsSection1;