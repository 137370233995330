import React from "react";
// Your Account
const TermsSection3 = () => {
    return (
        <div>
            <h3>3. Your Account</h3>
            To access the Services, you may be required to create an account. 
            It is your responsibility to safeguard your account, so use a strong password and limit its use to this account. 
            You are responsible for keeping your Flashycards login credentials secure, and you are entirely liable for all activities 
            that occur under those credentials. If you suspect unauthorised access to your account, please inform us immediately. 
            We will not be held accountable for any loss or damage caused by your failure to follow these instructions.
            <br/>
            <br/>
            Most communications from the Services can be managed by you. While you may be able to restrict certain communications, some 
            communications are essential to the Services and you may not be able to opt-out from receiving them. Additionally, 
            Flashycards retains the right to amend its policy regarding the distribution of commercial Content or the display of advertising at any time and without notice.
        </div>
    )
}

export default TermsSection3;