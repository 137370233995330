import React from "react";
// Who We Are
const PrivacySection1 = () => {
    return (
        <div>
            <h3>1. Who We Are</h3>
            
            If you live either inside or outside of the European Economic Area (“EEA”), the United Kingdom or Switzerland, the company responsible for your information under this Privacy Policy (the “data controller”) is:
            <br/>
            <br/>
            71-75 Shelton Street
            <br/>
            Covent Garden
            <br/>
            London
            <br/>
            WC2H 9JQ
            <br/>
            United Kingdom
        </div>
    )
}

export default PrivacySection1;