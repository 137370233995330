import React from "react";
// Protecting the Service and Maintaining a Trusted Environment
const PrivacySection5 = () => {
    return (
        <div>
            <h3>5. Protecting the Service and Maintaining a Trusted Environment</h3>
            We use all of the information we collect to ensure that Flashycards is a safe and secure platform for all of our users.  
            This involves enforcing Flashycards regulations and adhering to legal and regulatory standards. Our actions include:
            <ul>
                <li>
                    <b>Protecting the Service from fraud, criminal activity, and abuse while ensuring its security and integrity.</b> This includes 
                    investigating, identifying, preventing, or reporting instances of fraud, misrepresentation, security breaches, or 
                    other potentially banned or unlawful activity, all with the goal of improving account security.
                </li>
                <br/>
                <li>
                    <b>
                        Safeguarding our rights, as well as those of third parties, and enforcing our Terms of Service or other 
                        relevant agreements and policies, such as our Community Guidelines and Honour Code.
                    </b>
                </li>
                <br/>
                <li>
                    <b>Identity Verification.</b> In some situations, verifying your identity is 
                    required to maintain our service's security and integrity, and to protect your account.
                </li>
                <br/>
                <li>
                    <b>Adherence to any applicable laws and regulations.</b>  This includes 
                    responding to lawful requests for information from the government or other parties through legal channels.
                </li>
                <br/>
                <li>
                    <b>
                        Reaching out to you to address disputes, collect fees, and offer assistance with the Service.    
                    </b>
                </li>
            </ul>
        </div>
    )
}

export default PrivacySection5;