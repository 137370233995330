import React from "react";
// Modifying the Service
const TermsSection4 = () => {
    return (
        <div>
            <h3>4. Modifying the Service</h3>
            Flashycards is committed to constantly improving the Service with the goal of providing you with additional 
            engaging and beneficial features.  As a result, we may regularly release new product features or improvements, 
            while also removing others. We may not provide prior notification if these changes have no major impact on your rights or obligations.  
            We may stop the Service altogether in extraordinary circumstances, such as safety or security concerns, and we will warn you in 
            advance unless circumstances prevent us from doing so.
        </div>
    )
}

export default TermsSection4;