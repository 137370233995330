import React from "react";
import { Page, Container } from "../Styles";
import HelpCenterSection1 from "./HelpCenterSections/HelpCenterSection1";

const HelpCenter = () => {
    return (
        <Page>
            <Container>
                <h1>Help Center</h1>
                <HelpCenterSection1/>
            </Container>
        </Page>
    )
}

export default HelpCenter;