import React from "react";
import { Redirect } from "../../Styles";
// How do I delete my account?
const HelpCenterSection1 = () => {
    return (
        <div>
            <h3>1. How do I delete my account from within the app?</h3>
            To delete your account, navigate to the "Settings" screen in the Flashycards app and press the delete account button.
            From there, follow the on-screen instructions to delete your account.
            <br/>
            <br/>
            Please note, deleting your account will <i>permanently delete</i> any information or content relating to your account (unless otherwise
            stated in our <Redirect href="/terms">Terms</Redirect> and <Redirect href="/privacy">Privacy Policy</Redirect>) and cannot be undone.
        </div>
    )
}

export default HelpCenterSection1;