import React from "react";
// Eligibility
const TermsSection2 = () => {
    return (
        <div>
            <h3>2. Eligibility</h3>
            Where prohibited by law, use of the Service is banned. By using the Service, you represent and warrant that:
            <ul>
                <li>
                You are at least 13 years old
                </li>
                <br/>
                <li>
                All registration information you provide is true and accurate
                </li>
                <br/>
                <li>
                You will keep such information up to date
                </li>
                <br/>
                <li>
                Your use of the Service complies with all applicable laws and regulations
                </li>
                <br/>
                <li>
                You have not previously been banned from accessing our Services
                </li>
            </ul>
        </div>
    )
}

export default TermsSection2;