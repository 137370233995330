import React from "react";
// Your Rights, Responsibilities and Grant of Rights in the Content
const TermsSection6 = () => {
    return (
        <div>
            <h3>6. Your Rights, Responsibilities and Grant of Rights in the Content</h3>
            Any Content you submit, post, or display on or via the Services remains yours. Your Content (eg., text, images and audio files) is entirely yours.
            <br/>
            <br/>
            You are solely liable for the creation of any material you post or make available on the Service, 
            as well as any resulting harm. This is appropriate regardless of the format wherein the Content constitutes.
            <br/>
            <br/>
            By submitting, posting, or displaying Content on or through the Services, you grant us a worldwide, non-exclusive, 
            royalty-free licence (with the right to sublicense) to use, copy, reproduce, process, modify, publish, share, 
            display, and distribute such Content in any media or distribution methods, current or future (including curation, transformation, and translation). 
            This licence allows us to make your Content available worldwide and permits others to do the same. However, if you have chosen via 
            our Service to limit the availability of you Content, we will honour your decision.
            <br/>
            <br/>
            You also agree that this licence grants the right to analyse Content you supply in order to improve the Services. 
            Furthermore, you agree that we have the right to provide, market, and improve the Services under the terms of this licence. 
            It also enables us to make Content submitted to or through the Services available to other organisations for broadcast, distribution, 
            reposting, marketing, or publication on alternative media and services in line with our Agreement.  Whether by us or by other companies, 
            these additional uses are made without compensation for the Content you submit, publish, transmit, or otherwise make accessible through the Services.
        </div>
    )
}

export default TermsSection6;