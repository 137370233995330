import React from "react";

import {
    Box,
    FooterContainer,
    Row,
    Column,
    FooterLink,
    FooterText,
    Heading,
    Copyright
} from "./FooterStyles";
 
const Footer = () => {
    return (
        <Box>
            <FooterContainer>
                <Row>
                    <Column>
                        <Heading>Contact Us</Heading>
                        <FooterText href="#">
                            E: support@flashycards.app
                        </FooterText>
                    </Column>
                    <Column>
                        <Heading>Social</Heading>
                        <FooterLink href="https://www.tiktok.com/@flashycards.app?_t=8hLbIB67cJd&_r=1">
                            TikTok
                        </FooterLink>
                        <FooterLink href="https://www.instagram.com/flashycards.app/">
                            Instagram
                        </FooterLink>
                        <FooterLink href="https://www.youtube.com/channel/UC1MkkPQFQ5I92Zj2AWe8V4w">
                            YouTube
                        </FooterLink>
                    </Column>
                    <Column>
                        <Heading>Partnerships</Heading>
                        <FooterLink href="/affiliate">
                        Become an Affiliate
                        </FooterLink>
                    </Column>
                    <Column>
                        <Heading>Support & Resources</Heading>
                        <FooterLink href="/help">
                            Help Center
                        </FooterLink>
                        <FooterLink href="/terms">
                            Terms
                        </FooterLink>
                        <FooterLink href="/privacy">
                            Privacy Policy
                        </FooterLink>
                    </Column>
                </Row>
                <Copyright>
                    ©2023 - Flashycards Limited
                </Copyright>
            </FooterContainer>
        </Box>
    );
};

export default Footer;