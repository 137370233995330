import React from "react";
// Limitation of Warranties
const TermsSection11 = () => {
    return (
        <div>
            <h3>11. Limitation of Warranties</h3>
            FLASHYCARDS PROVIDES THE SERVICE ON AN “AS IS” AND “AS AVAILABLE” BASIS AND TO THE EXTENT PERMITTED BY APPLICABLE LAW, 
            GRANTS NO WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE WITH RESPECT TO THE SERVICE (INCLUDING ALL CONTENT 
            CONTAINED), INCLUDING, ALL WARRANTIES AND CONDITIONS, WHETHER EXPRESS OR IMPLIED, OF MERCHANTABILITY, FITNESS FOR A 
            PARTICULAR PURPOSE, OR NON-INFRINGEMENT. FLASHYCARDS DOES NOT REPRESENT OR WARRANT THAT (I) THE SERVICE WILL BE UNINTERRUPTED, 
            SECURE OR ERROR FREE, (II) ANY DEFECTS OR ERRORS IN THE SERVICE WILL BE CORRECTED, (III) THAT ANY CONTENT OR INFORMATION YOU 
            OBTAIN ON OR THROUGH THE SERVICE WILL BE ACCURATE, (IV) ANY HARM TO YOUR COMPUTER SYSTEM, LOSS OF DATA, OR ANY OTHER HARM THAT 
            RESULTS FROM YOUR ACCESS TO OR USE THE SERVICES, OR (V) FAILURE TO STORE OR TO TRANSMIT ANY CONTENT MAINTAINED BY THE SERVICES.
            <br/>
            <br/>
            FLASHYCARDS ACCEPTS NO RESPONSIBILITY FOR ANY CONTENT THAT YOU, ANOTHER MEMBER OR THIRD PARTY POSTS, SENDS OR RECEIVES THROUGH THE SERVICE. ANY CONTENT DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE IS TO BE USED AT YOUR OWN DISCRETION AND RISK.
        </div>
    )
}

export default TermsSection11;