import styled from "styled-components";

export const Page = styled.div`
    margin-top: 70px; // Added as NavigationBar height is 70px
    min-height: 80vh
`

export const Container = styled.div`
    padding: 2.5%;
    position: relative;
    bottom: 0;
    width: 95%;
 
    @media (max-width: 1000px) {
        padding: 2.5%;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
    margin: 0 auto;
`;

export const Redirect = styled.a`
    text-decoration: none;

    &:hover {
        color: #1375FF;
        transition: 200ms ease-in;
    }
`