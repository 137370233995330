import React from 'react';
import { Bar, BarContainer, BarBrandLogo } from './NavigationBarStyles';
import { ReactComponent as FlashycardsLogo } from '../../images/FlashycardsLogo.svg';

const NavigationBar = () => {
  return (
    <div>
      <Bar>
        <BarContainer>
            <BarBrandLogo to="/">
                <FlashycardsLogo height={70} alt='Flashycards Logo'/>
            </BarBrandLogo>
            {/* <BarGrid>
                <BarItem>
                    LOGIN
                </BarItem>
                <BarItem>
                    BUTTON
                </BarItem>
            </BarGrid> */}
        </BarContainer>
      </Bar>
    </div>
  );
}

export default NavigationBar;
