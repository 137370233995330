import React from "react";
// Subscriptions
const TermsSection10 = () => {
    return (
        <div>
            <h3>10. Subscriptions</h3>
            Flashycards may provide products and services available for purchase through authorised External Services such as iTunes, 
            Google Play, or other platforms approved by Flashycards (each referred to as an "External Service," and any transaction 
            conducted on such platforms termed an "External Service Purchase").
            <br/>
            <br/>
            In the event of a subscription purchase, it will automatically renew until you opt to cancel, as outlined in the terms 
            presented to you during the purchase process. Upon cancellation of your subscription, you will retain access to 
            subscription benefits until the conclusion of the subscription period, after which it will expire.
            <br/>
            <br/>
            Flashycards retains the rights to cancel your subscription at any time for any reason, without prior notice.
            <br/>
            <br/>
            Unless mandated by law, Subscriptions acquired directly from Flashycards are non-refundable. Refund policies for 
            Subscriptions obtained via other platforms are dependent upon the regulations of those specific platforms. 
            Flashycards is not accountable for the policies set forth by these platforms. Refunds will not be issued in the 
            event of a reduction in the price of Subscription plans, promotional pricing subsequent to purchase, or alterations 
            to the content or features of the Service.
            <br/>
            <br/>
            Flashycards conducts business worldwide, and our pricing structure is influenced by various factors. 
            We often present promotional rates, the specifics of which may differ depending on factors such as region, 
            subscription duration, and other considerations. Furthermore, we consistently engage in testing new features 
            and exploring diverse payment options as part of our ongoing efforts to enhance our service.
        </div>
    )
}

export default TermsSection10;