import React from "react";
// Privacy Policy Changes
const PrivacySection9 = () => {
    return (
        <div>
            <h3>9. Privacy Policy Changes</h3>
            This privacy policy may be updated from time to time.  
            Any important changes will be communicated to you in advance, allowing you the opportunity to assess them.
        </div>
    )
}

export default PrivacySection9;