import React from "react";
import { Page, Container } from "../Styles";
import TermsSection1 from "./TermsSections/TermsSection1";
import TermsSection2 from "./TermsSections/TermsSection2";
import TermsSection3 from "./TermsSections/TermsSection3";
import TermsSection4 from "./TermsSections/TermsSection4";
import TermsSection5 from "./TermsSections/TermsSection5";
import TermsSection6 from "./TermsSections/TermsSection6";
import TermsSection7 from "./TermsSections/TermsSection7";
import TermsSection8 from "./TermsSections/TermsSection8";
import TermsSection9 from "./TermsSections/TermsSection9";
import TermsSection10 from "./TermsSections/TermsSection10";
import TermsSection11 from "./TermsSections/TermsSection11";
import TermsSection12 from "./TermsSections/TermsSection12";

const Terms = () => {
    return (
        <Page>
            <Container>
                <h1>Terms of Service</h1>
                This Terms of Service Agreement applies from November 11, 2023.
                <br/><br/>
                <TermsSection1/>
                <br/><br/>
                <TermsSection2/>
                <br/>
                <TermsSection3/>
                <br/><br/>
                <TermsSection4/>
                <br/><br/>
                <TermsSection5/>
                <br/><br/>
                <TermsSection6/>
                <br/><br/>
                <TermsSection7/>
                <br/><br/>
                <TermsSection8/>
                <br/><br/>
                <TermsSection9/>
                <br/>
                <TermsSection10/>
                <br/><br/>
                <TermsSection11/>
                <br/><br/>
                <TermsSection12/>
                <br/>
            </Container>
        </Page>
    )
}

export default Terms;