import React from "react";
// How We Use Information
const PrivacySection4 = () => {
    return (
        <div>
            <h3>4. How We Use Information</h3>
            The primary purpose in utilising your information is to deliver and better our services.  
            Furthermore, we use your information to keep your and our community safe, and to serve you with advertisements that may be of interest to you.
            <br/>
            <br/>
            The main reason we use your information is to deliver and improve our services.  
            Additionally, we use your information to help ensure the safety of you and our community, as well as to offer you 
            with advertising that aligns with your interests.  Continue reading for a more extensive description as 
            well as practical example of the various reasons for which we use your information.
            <ul>
                <li>
                    <b>To administer your account and provide our services to you.</b>  
                    This includes, the creation and management of your account, provide you with customer support 
                    and responses to your requests, communication with you about our services.  As well as, 
                    personalised pricing, offer discounts and other promotions, and complete your transactions, and contests
                </li>
                <br/>
                <li>
                    <b>Supplying, Provisioning and Bettering our services.</b>  
                    We collect information from you so that we can assist you with your log in, host 
                    your material, and make our many learning tools more accessible.  
                    Additionally, it provides us with insights on how you and others use Flashycards, assisting us in 
                    the development of new features and services.
                </li>
                <br/>
                <li>
                    <b>Acquiring metrics and utilising analytics to Improving the services.</b>  
                    To develop and improve our products and services, we track, measure and analyse trends and usage 
                    associated with your use of our services.  To gain a better understanding of our mobile device 
                    functionality, we also utilise mobile analytics to pursue our legitimate interests in operating 
                    and improving Flashycards.  This software may collect information such as the frequency with which 
                    the application is used, events and actions inside the application, aggregated use, 
                    performance statistics, and the source from which the application was downloaded.
                </li>
                <br/>
                <li>
                    <b>
                    Carrying out any additional purposes made known to you in connection with our services.
                    </b>
                </li>
            </ul>
            <br/>
            <br/>
            <b><i>How we share your information</i></b>
            <br/>
            <br/>
            We may use your account information or any other information you publish to us to 
            provide you with information relating to our services and to assist your use of our services if necessary.  
            These communications may include:
            <br/>
            <br/>
            <ul>
                <li>
                    <b>Providing you with information we believe may be beneficial or that you have specifically requested regarding our products and services.</b> This 
                    may contain information about features that you use or that are relevant to you.  
                    Although our primary channels for such communication are our social media accounts, and we 
                    try to limit related emails, you have the option of stating your objection to being contacted for these 
                    reasons by using the mechanisms available in the Flashycards service or by contacting our support team.
                </li>
                <br/>
                <li>
                    <b>Administering surveys and collecting feedback on the Service.</b> This is done to evaluate the success of each modification we implement.
                </li>
                <br/>
                <li>
                    <b>Providing assistance and responding to support requests.</b> We provide you with the information 
                    and assistance you require, such as technical notices, security warnings, and other administrative 
                    messages, to assist you in resolving any difficulties you may have with the Service or your Flashycards account.
                </li>
            </ul>
            <br/>
            <br/>
            <b><i>Information You Choose to Share</i></b>
            <br/>
            <br/>
            Some information about you or the actions you perform on our services, such as details on your profile, 
            decks you've created and made publicly visible, statistics you've obtained (e.g., experience points), 
            may be publicly published.  We attempt to provide you with options about the privacy of your information on Flashycards wherever applicable.
        </div>
    )
}

export default PrivacySection4;