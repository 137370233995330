import React from "react";
// Termination of Terms
const TermsSection5 = () => {
    return (
        <div>
            <h3>5. Termination of Terms</h3>
            You may terminate your legal agreement with us at any time by deleting your account and discontinuing usage of the Services. 
            Refer to our help centre for instructions on deleting your account.
            <br/>
            <br/>
            The simplest way to terminate an account is to follow the steps in the Service's "Settings" section. Alternative methods of termination are available. 
            Manage in-app purchases using a third-party payment account, such as Apple's App Store or iTunes Store, or the Google Play Store, 
            to avoid unnecessary invoicing. If Flashycards suspects a breach of this Agreement, it retains the right to terminate your account without warning. 
            Purchases will not be refunded in the case of such termination.  If your account is terminated by you or Flashycards for any reason, 
            these Terms continue to apply and remain enforceable between you and Flashycards.
        </div>
    )
}

export default TermsSection5;