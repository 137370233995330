import React from "react";
// Where This Privacy Policy Applies
const PrivacySection2 = () => {
    return (
        <div>
            <h3>2. Where This Privacy Policy Applies</h3>
            This Privacy Policy concerns the websites, applications, events, and various other services offered under the Flashycards brand.  
            Throughout this Privacy Policy, we will refer to these offers collectively as our “services”.  
            We've included links to this Privacy Policy on all relevant services to make it easily accessible.
        </div>
    )
}

export default PrivacySection2;