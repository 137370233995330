import React from "react";
// Prohibited Content
const TermsSection9 = () => {
    return (
        <div>
            <h3>9. Prohibited Content</h3>
            Flashycards reserves the right to investigate and take appropriate legal action against anybody who breaches the following 
            prohibitions in its sole discretion.  This may include, but is not limited to, deleting the infringing Content from the Service, 
            limiting access to any component of the Service, terminating such offenders' accounts, or pursuing other legal actions.  
            The following examples (although not exhaustive) represent the types of Contentthat any platforms or 
            services offered by Flashycards prohibit from being posted:
            <ul>
                <li>
                Any and all applicable laws or regulations are violated
                </li>
                <br/>
                <li>
                Violates the privacy rights, publicity rights, copyrights, trademark rights or any other rights of any person
                </li>
                <br/>
                <li>
                Public Content that compromises the privacy or security of any person
                </li>
                <br/>
                <li>
                Content relating to any person posted without their consent
                </li>
                <br/>
                <li>
                Subject matter deemed sexually provocative, violent, or objectionable
                </li>
                <br/>
                <li>
                Promotes any form of harassment or hate speech
                </li>
                <br/>
                <li>
                Contains profanity or offensive language
                </li>
            </ul>
        </div>
    )
}

export default TermsSection9;