import React from "react";
import { Page, Container } from "../Styles";
import PrivacySection1 from "./PrivacySections/PrivacySection1";
import PrivacySection2 from "./PrivacySections/PrivacySection2";
import PrivacySection3 from "./PrivacySections/PrivacySection3";
import PrivacySection4 from "./PrivacySections/PrivacySection4";
import PrivacySection5 from "./PrivacySections/PrivacySection5";
import PrivacySection6 from "./PrivacySections/PrivacySection6";
import PrivacySection7 from "./PrivacySections/PrivacySection7";
import PrivacySection8 from "./PrivacySections/PrivacySection8";
import PrivacySection9 from "./PrivacySections/PrivacySection9";

const Privacy = () => {
    return (
        <Page>
            <Container>
                <h1>Privacy Policy</h1>
                This Privacy Policy applies from November 11, 2023.
                <br/><br/>
                <PrivacySection1/>
                <br/><br/>
                <PrivacySection2/>
                <br/><br/>
                <PrivacySection3/>
                <br/><br/>
                <PrivacySection4/>
                <br/><br/>
                <PrivacySection5/>
                <br/><br/>
                <PrivacySection6/>
                <br/><br/>
                <PrivacySection7/>
                <br/>
                <PrivacySection8/>
                <br/><br/>
                <PrivacySection9/>
                <br/>
            </Container>
        </Page>
    )
}

export default Privacy;