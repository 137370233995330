import styled from "styled-components";

export const Box = styled.div`
    padding: 2.5% 2.5% 0% 2.5%;
    background: white;
    position: relative;
    bottom: 0;
    width: 95%;
    box-shadow: 0px 0px 0px 2px #DCDCDC;
 
    @media (max-width: 1000px) {
        padding: 5% 2.5% 0% 2.5%;
    }
`;
 
export const FooterContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
    margin: 0 auto;
`;
 
export const Column = styled.div`
    display: flex;
    flex-direction: column;
    vertical-align: top;
    margin-left: 20px;
`;
 
export const Row = styled.div`
    display: grid;
    grid-template-columns: repeat(
        auto-fill,
        minmax(200px, 1fr)
    );
    grid-gap: 20px;
 
    @media (max-width: 1000px) {
        grid-template-columns: repeat(
            auto-fill,
            minmax(200px, 1fr)
        );
    }
`;

export const FooterText = styled.a`
    color: #646464;
    margin-bottom: 20px;
    text-decoration: none;
    cursor: text;
`;
 
export const FooterLink = styled.a`
    color: #646464;
    margin-bottom: 20px;
    text-decoration: none;
 
    &:hover {
        color: #1375FF;
        transition: 200ms ease-in;
    }
`;
 
export const Heading = styled.body`
    color: #4C4C4C;
    margin-bottom: 20px;
    font-weight: bold;
`;

export const Copyright = styled.p`
    text-align: center;
    color: #646464;
`;