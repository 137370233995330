import React from "react";
import { Page, Container } from "../Styles";

const Affiliate = () => {
    return (
        <Page>
            <Container>
                <h1>Become an Affiliate</h1>
                More information coming soon!
            </Container>
        </Page>
    )
}

export default Affiliate;