import React from "react";
// How We Keep Your Information Secure
const PrivacySection8 = () => {
    return (
        <div>
            <h3>8. How We Keep Your Information Secure</h3>
            Flashycards puts an intense emphasis on the protection of your personal information. 
            We use appropriate measures to prevent unauthorised access, modification or deletion of your personal data.
            <br/>
            <br/>
            We make every effort to secure the personal information you disclose to us, 
            both during transmission and after reception, by adhering to well recognised industry standards (e.g., HTTPS encryption). 
            However, it is important to note that the Internet is not completely secure, and we cannot guarantee that your use of 
            our sites will be completely free of risk.  When using the internet we strongly advise you take great caution.  
            If you have concerns about the security measures in place on our services, please contact our support team.
        </div>
    )
}

export default PrivacySection8;