import React from "react";
import { Page, Container } from "../Styles";

const Main = () => {
    return (
        <Page style={{  backgroundColor: "#173974",
                        minHeight: "80vh", 
                        display: "flex",  
                        justifyContent: "center", 
                        alignItems: "center", 
                        textAlign: "center"}}>  
            <Container>
                <h1 style={{fontSize: "4.5em", color: "#FFFFFF"}}>COMING SOON!</h1>
            </Container>
        </Page>
    );
}

export default Main;