import React from "react";
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import NavigationBar from "./components/NavigationBar/NavigationBar.js";
import Footer from "./components/Footer/Footer.js";
import HelpCenter from "./components/HelpCenter/HelpCenter.js";
import Terms from "./components/Terms/Terms.js";
import Privacy from "./components/Privacy/Privacy.js";
import Main from "./components/Main/Main.js";
import Affiliate from "./components/Affiliate/Affiliate.js";

function App() {
    return (
        <Router>
            <NavigationBar/>
            <Routes>
                <Route path="/" element={<Main/>}/>
                <Route path="/affiliate" element={<Affiliate/>}/>
                <Route path="/help" element={<HelpCenter/>}/>
                <Route path="/terms" element={<Terms/>}/>
                <Route path="/privacy" element={<Privacy/>}/>
              </Routes>
            <Footer />
        </Router>
    );
}

export default App;