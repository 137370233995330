import React from "react";
// Your Licence to Use the Services
const TermsSection7 = () => {
    return (
        <div>
            <h3>7. Your Licence to Use the Services</h3>
            Flashycards hereby grants you a personable, worldwide, royalty-free, non-assignable, and non-exclusive license to 
            utilize the software incorporated into the Services. This licence is solely for the purpose of allowing you to use 
            and enjoy the advantages of the Service as intended and as authorised by this Agreement.
            <br/>
            <br/>
            Copyright, trademark, and other relevant laws in the United States and other jurisdictions protect the Services. 
            These Terms do not provide you the right to use the Flashycards name or any of the Flashycards trademarks, logos, domain names, 
            or other property rights. All rights, titles, and interests in and to the Services (other than user-provided Content) 
            remain the exclusive property of us and our licensors. Any voluntary feedback or comments you provide concerning the Services 
            are totally voluntary, and we reserve the right to use such information at our discretion.
        </div>
    )
}

export default TermsSection7;