import React from "react";
// Misuse of the Services
const TermsSection8 = () => {
    return (
        <div>
            <h3>8. Misuse of the Services</h3>
            You engage in any misuse of the Services, including interfering with any means other than the interface and instructions provided by us. 
            Except to the extent expressly authorised by applicable law, you agree not to breach any technological limits in the software provided 
            as part of the Services, nor will you engage in reverse engineering, decompiling, or disassembling the software.
        </div>
    )
}

export default TermsSection8;