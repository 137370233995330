import React from "react";
import { Redirect } from "../../Styles";
// Your Rights and Choices
const PrivacySection6 = () => {
    return (
        <div>
            <h3>6. Your Rights and Choices</h3>
            We believe in enabling you to control your information, so we would like to emphasise the different options and tools available to you:
            <ul>
                <li>
                    <b>Access / Update tools in the service.</b> These tools and 
                    account settings provide you the ability to modify, or remove information directly related to your account. 
                    If you have any questions about these tools and settings, please contact our support team for assistance.
                </li>
                <br/>
                <li>
                    <b>Device permissions.</b> Mobile platforms may have permission systems governing 
                    specific types of device data and notifications, including phone contacts, pictures, 
                    location services, push notifications, and advertising identifiers.  
                    You can configure your device to enable or prohibit the collection or processing of associated information, 
                    as well as the display of connected notifications. It is important to remember that 
                    changing these settings may result in the loss of certain service functionality.
                </li>
                <br/>
                <li>
                    <b>Uninstall.</b> To prevent an app from collecting any information, uninstall it using your device's uninstall procedure.  
                    It's important to note that deleting an app does not delete your account. 
                    Please use the relevant feature within the service to delete your acc
                </li>
                <br/>
                <li>
                    <b>Account deletion.</b> You may delete your account by using the corresponding functionality directly on the service.
                </li>
                <br/>
            </ul>
            We also want to ensure that you are understanding of your privacy rights. Depending on where you live, you may be eligible for:
            <ul>
                <li>
                    <b>Access/know.</b> You may have the right to request a copy of the information we 
                    keep on you and, in some cases, to obtain it in a portable format. You can exercise 
                    your right to direct access by contacting our support team.
                </li>
                <br/>
                <li>
                    <b>Delete/erase.</b> You may request that we delete the personal information we keep about you. 
                    You can exercise your right to delete by contacting our support team.

                </li>
                <br/>
                <li>
                    <b>Correct/rectify/update.</b> You can correct most information you provided to us by editing 
                    your profile directly in the service. If you believe the information we hold about you is inaccurate, 
                    you may contact our support team to rectify it.
                </li>
                <br/>
                <li>
                    <b>Object/restrict.</b> You may also have the right to object to or request that we 
                    restrict certain processing. To do so, please contact our support team
                </li>
                <br/>
            </ul>
            For your protection and the protection of all of our users, we may request proof of identity before addressing the aforementioned requests.  
            Please note that we reserve the right to reject requests, including instances where authentication is not feasible, 
            the request is unlawful or invalid, or if it may violate trade secrets, intellectual property, privacy, or the rights of others.  
            <br/>
            <br/>
            Certain requests to object to or restrict the processing of personal information may not be accommodated, 
            particularly if fulfilling such requests would impede our ability to provide our service.
            <br/>
            <br/>
            Residents of Virginia, Colorado, or Connecticut, USA, who have had their privacy requests refused, may appeal by emailing our support team 
            and referencing "Privacy Request Appeal." If your concerns remain, you may contact your state's attorney general.  
            Particularly, we do not participate in the "sale" of personal data or use it for "targeted advertising" or "profiling" 
            in methods described by applicable state legislation, therefore there is no need for an opt-out choice.
            <br/>
            <br/>
            In certain countries, including the European Economic Area and the United Kingdom, 
            you have the right to file a complaint with the appropriate data protection authority 
            if you are concerned about how we handle your personal information. Information about 
            your data protection regulator in the European Economic Area can be found <Redirect href="https://edpb.europa.eu/about-edpb/about-edpb/members_en">here</Redirect>, 
            and in the United Kingdom <Redirect href="https://ico.org.uk">here</Redirect>. You may choose to lodge a 
            complaint with the data protection authority based on your habitual residence, place of work, or the location of an alleged violation.
        </div>
    )
}

export default PrivacySection6;